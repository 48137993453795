import React, { useContext } from "react";
import Sidebar from './Sidebar'
import Chat from './Chat'
import { AuthContext } from "../AuthContext";
import { Navigate } from "react-router-dom";
import {signOut} from "firebase/auth"
import { auth } from '../firebase'

const ChatHome = () => {

  const { currentUser } = useContext(AuthContext);

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" />;
    }

    return children
  };
  return (
    <div className='home'>
      <div className="outset">
      <ProtectedRoute>
      <div className="container">
        <Sidebar/>
        <Chat/>
        </div>
        <button onClick={()=>signOut(auth)}>logout</button>
        </ProtectedRoute>
      </div>
    </div>
  )
}

export default ChatHome