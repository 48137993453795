import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Grommet } from "grommet";
import Home from "./components/Home/Home";
import SearchPage from "./components/Search/Search";
import Account from "./components/Account/Account";
import Calendar from "./components/Calendar/Calendar";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import ChatHome from "./components/Messages/ChatHome";
import About from "./components/About/About";
import MissionStatement from "./components/About/MissionStatement";
import NavBar from "./components/NavFrame/Header";
import Feedback from "./components/Feedback/Feedback";
import ViewAccount from "./components/ViewAccount/ViewAccount";
import { Toaster } from "react-hot-toast";
import { Amplify } from "aws-amplify";
import "./style.scss";
import { AuthContextProvider } from "./components/AuthContext.js";
import { ChatContextProvider } from "./components/ChatContext.js";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const theme = {
  global: {
    colors: {
      yellow_green_crayola: "#d9ed92ff",
      yellow_green_crayola_2: "#b5e48cff",
      "accent-1": "#99d98cff",
      ocean_green: "#76c893ff",
      keppel: "#52b69aff",
      viridian_green: "#34a0a4ff",
      blue_munsell: "#168aadff",
      cg_blue: "#1a759fff",
      lapis_lazuli: "#1e6091ff",
      candy_pink: "#dd4045ff",
      violet: "#6d597aff",
      russian_green: "#006633ff",
      brand: "#184e77ff",
      brand_gray: "#bdc5d0",
    },
  },
};

function App() {
  const [account, setAccount] = useState("");
  return (
    <Grommet full theme={theme}>
      <BrowserRouter>
        <Toaster position="top-center" />
        <NavBar {...{ account, setAccount }} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/account/:username" element={<Account />} />
          <Route path="/mission" element={<MissionStatement />} />
          <Route path="/about" element={<About />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/messages"
            element={
              <AuthContextProvider>
                <ChatContextProvider>
                  <ChatHome />
                </ChatContextProvider>
              </AuthContextProvider>
            }
          />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/:username" element={<ViewAccount />} />
        </Routes>
      </BrowserRouter>
    </Grommet>
  );
}

export default App;
