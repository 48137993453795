import React from "react";
import { Box } from "grommet";

export default function About() {
  return (
    <Box fill background="brand_gray">
      <Box margin={{ left: "small" }}>
        <h2>About</h2>
        <h3>About the Creator</h3>
        <p>
          Dr. Rafferty (PhD, University of Wisconsin-Milwaukee) conducts research in interpersonal
          health communication with a focus on how patients <br /> and families manage and cope with
          chronic illness and end-of- life care. Katherine is an associate teaching professor at
          Iowa State University.
          <br /> She teaches undergraduate communication courses and mentors undergraduate students
          in their independent research projects. <br /> In Fall 2018 and Spring 2020, she received
          a University grant to develop an online health communication course for undergraduate{" "}
          <br />
          pre-health professionals and other social science majors. Prior to earning her PhD,
          Katherine worked as a Health Communication Specialist <br />
          for the Centers for Disease Control & Prevention (CDC) in Atlanta, Georgia.
        </p>
        <h3>About the Logo Design</h3>
        <p>
          {" "}
          For developing the website logo, it was determined to reach out to a professional artist
          to help with the design. Emily A. Fitzpatrick
          <br /> studied at Iowa State University from 2014 until 2018. She graduated with a degree
          in Visual Arts and Culture. More of her work can be <br />
          found{" "}
          <a href="https://www.emilyafitzpatrick.com/" target="_blank" rel="noreferrer">
            here
          </a>
          .
        </p>
        <h4>About the Developers</h4>
        <p>
          {" "}
          To start this website, there was a team of three developers. <br />
          Samantha Culver is a Senior in Software Engineering with minors in Animal Science and
          Cybersecurity. Her goal is to
          <br /> help improve the medical technology field and specifically she wishes to improve
          technology available for
          <br />
          animal healthcare. She is passionate about this website because it is her first step to
          improving medical-related technology <br /> and allowed her to make a positive impact on
          the lives of people in need.
        </p>

        <p>
          Kellan Hulet is a senior in Computer Engineering at Iowa State. He is focused on app
          development with a specialty on
          <br />
          the front end. He chose this project because he wants to help people get the support they
          need in times of crisis.
        </p>

        <p>
          Siddharth Rana is a senior in Software Engineering. He is a full stack developer,
          focussing on performance and optimization. <br />
          He joined in on the parakeet.care project because he was excited to make help more readily
          available in form of a beautiful <br />
          website for people in times of crisis.
        </p>
      </Box>
    </Box>
  );
}
