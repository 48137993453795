// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC6dRvBJ0DtyOx2Blh4rK9xIp7J2Yis5TE",
  authDomain: "chat-5a43c.firebaseapp.com",
  projectId: "chat-5a43c",
  storageBucket: "chat-5a43c.appspot.com",
  messagingSenderId: "629936766136",
  appId: "1:629936766136:web:60780e864a0ed3af9dc84d",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore();
