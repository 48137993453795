import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Avatar,
  Text,
  List,
  Grid,
  Form,
  FormField,
  Select,
  TextArea,
  Button,
  Layer,
  TextInput,
  DateInput,
  CheckBox,
  RadioButtonGroup,
  Heading,
} from "grommet";
import toast from "react-hot-toast";
import { Chat, Favorite, Checkmark, More, Alert } from "grommet-icons";
import chooseBgColor from "../../utility/chooseBgColor";

function ActivityDisplay({ userData }) {
  const [changeStyle, setChangeStyle] = useState("white");
  return (
    <Box>
      <List data={userData} pad="none" align="center">
        {(datum) => (
          <Box background={chooseBgColor(datum.activityType)} pad="small">
            <Grid
              columns={["xxsmall", "flex"]}
              rows={["flex"]}
              gap="xsmall"
              areas={[
                { name: "avatar", start: [0, 0], end: [0, 0] },
                { name: "main", start: [1, 0], end: [1, 0] },
              ]}
            >
              <Box gridArea="avatar" width="50px">
                <Avatar
                  size="40px"
                  src={`${process.env.PUBLIC_URL}/assets/${datum.username}.jpg`}
                />
              </Box>
              <Box gridArea="main" gap="small">
                <Box direction="row" justify="between" align="center">
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to={`/${datum.userId}`}
                  >
                    <Text size="large">{datum.username}</Text>
                  </NavLink>

                  <Text size="medium">{datum.timePosted}</Text>
                </Box>
                <Text>{datum.data}</Text>

                <Box direction="row" gap="medium">
                  <Box direction="row" gap="small">
                    <Chat />
                    <Text>15</Text>
                  </Box>
                  <Box direction="row" gap="small">
                    <Favorite
                      color={changeStyle}
                      onClick={() => setChangeStyle(changeStyle === "white" ? "red" : "white")}
                    />
                    <Text>205</Text>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        )}
      </List>
    </Box>
  );
}

const defaultReqSupportValues = {
  name: "",
  groups: "",
  date: "",
  comments: "",
};

function RequestSupportForm() {
  const [value, setValue] = useState(defaultReqSupportValues);
  return (
    <Box align="center" justify="center">
      <Form
        value={value}
        onChange={(nextValue) => {
          setValue(nextValue);
        }}
        onReset={() => setValue(defaultReqSupportValues)}
        onSubmit={() => setValue(defaultReqSupportValues)}
      >
        <FormField label="Request Title" name="name" required>
          <TextInput name="name" />
        </FormField>
        <FormField label="Groups" name="groups" required>
          <Select name="groups" options={["Friends", "Family", "Community", "Public"]} />
        </FormField>
        <FormField label="Date" name="date" required>
          <DateInput name="date" format="mm/dd/yy" />
        </FormField>
        <FormField label="Description" name="comments" required>
          <TextArea name="comments" />
        </FormField>
        <Box direction="row" justify="between" margin={{ top: "medium" }}>
          <Button
            type="submit"
            label="Request"
            primary
            onClick={() => toast.success("Support Requested!")}
          />
          <Button type="reset" label="Reset" />
        </Box>
      </Form>
    </Box>
  );
}

function AccountSettingsForm({ account }) {
  const defaultAcctSettingsValues = {
    name: account.name.fullName,
    bio: account.bio,
    privacy: account.privacy !== "Public",
    acctType: account.type,
    darkMode: false,
  };

  const [value, setValue] = useState(defaultAcctSettingsValues);
  const [radioValue, setRadioValue] = useState(account.type);
  return (
    <Box align="center" justify="center">
      <Form
        value={value}
        onChange={(nextValue) => {
          setValue(nextValue);
        }}
        onReset={() => setValue(defaultAcctSettingsValues)}
        onSubmit={() => setValue(defaultAcctSettingsValues)}
      >
        <FormField label="Name" name="name">
          <TextInput name="name" />
        </FormField>
        <FormField label="Bio" name="bio">
          <TextArea name="bio" />
        </FormField>
        <FormField label="Private Account?" name="privacy" direction="row" align="center">
          <CheckBox name="privacy" />
        </FormField>
        <FormField label="Account Type" name="acctType">
          <RadioButtonGroup
            name="acctType"
            options={["Supporter", "Receiver"]}
            value={radioValue}
            onChange={(event) => setRadioValue(event.target.value)}
          />
        </FormField>
        <FormField label="Dark Mode?" name="darkMode" direction="row" align="center">
          <CheckBox name="darkMode" />
        </FormField>
        <Box direction="row" justify="between" margin={{ top: "medium" }}>
          <Button
            type="submit"
            label="Update"
            primary
            onClick={() => toast.success("Account settings updated!")}
          />
          <Button type="reset" label="Reset" />
        </Box>
      </Form>
    </Box>
  );
}

function ActiveRequests({ requests }) {
  const { requestsList, goFundMe } = requests;
  const [show, setShow] = useState(false);
  if (!requests) {
    return <Box align="center">No requests created yet!</Box>;
  }
  return (
    <Box align="center">
      {goFundMe && (
        <Box
          align="center"
          width="100%"
          flex="grow"
          margin={{ bottom: "small" }}
          justify="around"
          direction="row"
        >
          GoFundMe Link: {goFundMe} <Button label="edit" onClick={() => setShow(true)} />
          {show && (
            <Layer
              background="brand"
              onEsc={() => setShow(false)}
              onClickOutside={() => setShow(false)}
              position="center"
            >
              <Box pad="medium">
                <Heading>Edit GoFundMe Link</Heading>
                <Form>
                  <FormField name="GFM">
                    <TextInput name="GFM" />
                  </FormField>
                  <Box direction="row" justify="between" margin={{ top: "medium" }}>
                    <Button
                      type="submit"
                      label="Update"
                      primary
                      onClick={() => toast.success("GoFundMe link updated!")} //should call API to update GoFundMe link after successful mutation
                    />
                    <Button type="reset" label="Reset" />
                  </Box>
                </Form>
              </Box>
            </Layer>
          )}
        </Box>
      )}
      {requestsList.map((request) => {
        let bgColor = chooseBgColor(request.status);
        return (
          <Box
            key={request.title}
            background={bgColor}
            fill
            margin={{ bottom: "small" }}
            border={{
              color: "accent-1",
              size: "small",
              side: "horizontal",
            }}
          >
            <Box align="center" justify="between" direction="row" margin="small">
              <Text size="xxlarge" weight="bold" margin={{ right: "medium" }}>
                {request.title}
              </Text>
              <Text size="large">{request.date}</Text>
            </Box>
            <Box
              direction="row"
              justify="between"
              margin={{ horizontal: "small", bottom: "small" }}
            >
              <Text size="xlarge" margin={{ right: "medium" }}>
                {request.supporter}
              </Text>
              <Box size="large">{request.completed === "Complted" ? <Checkmark /> : <More />}</Box>
            </Box>
            <Box margin={{ horizontal: "small", bottom: "medium" }}>{request.description}</Box>
          </Box>
        );
      })}
    </Box>
  );
}

function GroupsDisplay({ groups }) {
  if (!groups) {
    return <Box align="center">No groups created yet!</Box>;
  }
  const renderGroups = groups.map((group) => {
    return (
      <Box
        key={group.groupName}
        margin={{ horizontal: "small", bottom: "small" }}
        round
        border={{
          color: "accent-1",
          size: "small",
          side: "all",
        }}
      >
        <Box margin={{ horizontal: "medium", vertical: "small" }}>
          <Box direction="row" justify="between" margin={{ bottom: "xsmall" }}>
            <Text size="xxlarge" weight="bold">
              {group.groupName}
            </Text>
            <Button label="Edit" />
          </Box>
          {group.members.map((member) => {
            return <Box key={member.name}>{member.name}</Box>;
          })}
        </Box>
      </Box>
    );
  });
  return renderGroups;
}

function ActivityPage({ menuChoice, userData }) {
  switch (menuChoice) {
    case "Activity":
      return <ActivityDisplay userData={userData.activity} />;
    case "Requests":
      return <ActiveRequests requests={userData.requests} />;
    case "Request Support":
      return <RequestSupportForm />;
    case "Groups":
      return <GroupsDisplay groups={userData.groups} />;
    case "Account Settings":
      return <AccountSettingsForm account={userData.account} />;
    case "Help":
      return (
        <Box align="center">
          <Alert />
          Under Construction
        </Box>
      );
    default:
      return <Box>Error</Box>;
  }
}

export default ActivityPage;
