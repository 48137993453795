export default function chooseBgColor(type) {
  switch (type) {
    case "Unfulfilled":
    case "Request":
      return "candy_pink";
    case "Scheduled":
    case "Update":
      return "violet";
    case "Completed":
    case "Misc":
      return "russian_green";
    default:
      return "brand";
  }
}
