import React, { useState } from "react";
//import UserPool from "../../UserPool";
import { Form, FormField, Button, TextInput, Box, DateInput, RadioButtonGroup } from "grommet";
import toast from "react-hot-toast";

// THIS PAGE IS NOT LONGER IN USE
// REPLACED BY AWS AMPLIFY UI
// CONTAINS WORKING CODE FOR CUSTOM REGISTRATION PAGE

const defaultValues = {
  email: "",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  zipCode: "",
  password: "",
  acctType: "Supporter",
};

function Register() {
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [value, setValue] = useState(defaultValues);
  const onSubmit = () => {
    setValue(defaultValues);
    toast.success("Account created!");
    // event.preventDefault();

    // UserPool.signUp(email, password, [], null, (err, data) => {
    //   if (err) {
    //     console.error(err);
    //   }
    //   console.log(data);
    // });
  };

  return (
    <Box justify="start" gap="small" align="center">
      <Box gap="small" width="medium" margin="small">
        <Form
          value={value}
          onChange={(nextValue) => {
            setValue(nextValue);
          }}
          onReset={() => setValue(defaultValues)}
          onSubmit={onSubmit}
        >
          <FormField name="email" label="Email">
            <TextInput
              name="email"
              // value={email}
              // onChange={(event) => setEmail(event.target.value)}
            />
          </FormField>
          <Box direction="row" gap="xsmall">
            <FormField name="firstName" label="First Name">
              <TextInput name="firstName" />
            </FormField>
            <FormField name="lastName" label="Last Name">
              <TextInput name="lastName" />
            </FormField>
          </Box>
          <FormField name="dateOfBirth" label="Date of Birth">
            <DateInput name="dateOfBirth" format="mm/dd/yy" />
          </FormField>
          <FormField name="zipCode" label="Zip Code">
            <TextInput name="zipCode" />
          </FormField>
          <FormField name="password" label="Password">
            <TextInput
              name="password"
              // value={password}
              // onChange={(event) => setPassword(event.target.value)}
            />
          </FormField>
          <FormField name="acctType" label="Account Type">
            <RadioButtonGroup direction="row" name="acctType" options={["Receiver", "Supporter"]} />
          </FormField>
          <Box direction="row" gap="medium">
            <Button type="submit" label="Submit" />
            <Button type="reset" label="Reset" />
          </Box>
        </Form>
      </Box>
    </Box>
  );
}

export default Register;
