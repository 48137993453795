import React from "react";
import { Box, Heading, Image, Paragraph } from "grommet";

export default function MissionStatement() {
  return (
    <Box background="brand_gray">
      <Box margin={{ left: "small" }}>
        <Heading level="2">Mission Statement</Heading>
        <Box direction="row" fill gap="xlarge">
          <Paragraph>
            The goal of Parakeet is to help individuals who are struggling during a medical crisis.
            Studies show that currently, many people struggle to get the support they need from
            loved ones and family. This is due to the pressure that language and expectations put
            onto the individuals in need. Research into communication show that it is much better
            to, instead of asking someone what can be done to help them, it's more effective to
            simply offer specific choices to the person in need. Parakeet.care will support people
            who are in crises (be them medical crises or natural disasters) by organizing the
            communities around the people in need to provide the exact support that is required.
          </Paragraph>
          <Box height="medium" width="medium">
            <Image fit="cover" src={process.env.PUBLIC_URL + "/ParakeetLogo.jpg"} />
          </Box>
        </Box>
        <Heading level="2">Where it Started</Heading>
        <p>
          This web app idea was born in Fall 2011 when Katherine Rafferty started her PhD program in
          Communication at the University
          <br />
          of Wisconsin-Milwaukee. That first semester she took a night seminar on supportive
          communication with Dr. Jennifer Peterson.
          <br />
          She also started volunteering with her then boyfriend (now husband) at the Ronald McDonald
          House Charities of Eastern Wisconsin.
          <br />
          Through this class and her volunteer experiences with her husband, she developed a passion
          for helping others during health
          <br />
          crises, and in particular understanding others support needs and how to mobilize their
          support networks in positive and helpful
          <br />
          ways.
          <br />
          From the social support literature, we know that not all support is helpful (Robinson et
          al., 2019). Too much support or poor
          <br />
          social support actually creates more problems for both patients and family caregivers,
          such as a heavy emotional and
          <br />
          financial burden for caregivers (Roy, 2011). In addition, caregivers are tasked with lots
          of different types of work when
          <br />
          caring for their family members with chronic or acute illness. One type of work is
          "communication work" (Donovan-Kicken,
          <br />
          Tollison, & Goins, 2012). This work involves managing talk about a family member's illness
          with other members in their
          <br />
          support networks (e.g., providing health updates, coordinating care with other people).{" "}
          <br />
          Given all of these findings and her own program of research on parents of children with
          complex medical conditions,
          <br />
          Katherine has learned that there are heightened moments of illness when families need help
          from others. Asking for help
          <br />
          can be a face-threatening act that is both challenging and difficult to do. Thus, the goal
          of this app is to help people
          <br />
          in health crisis better mobilize their support networks and receive the helpful support
          that they need and deserve!
        </p>
        <h3>Supporting Research</h3>
        <p>
          Donovan-Kicken E, Tollison AC, Goins ES. The nature of communication work during cancer:
          advancing the theory of illness
          <br />
          trajectories. Health Commun. 2012;27(7):641-52. doi: 10.1080/10410236.2011.629405. Epub
          2011 Dec 14. PMID: 22168391.
          <br />
          <br />
          James D. Robinson, Jeanine W. Turner, Yan Tian, Alan Neustadtl, Seong Ki Mun & Betty
          Levine (2019) The Relationship
          <br />
          between Emotional and Esteem Social Support Messages and Health, Health Communication,
          34:2, 220-226, <br />
          DOI: 10.1080/10410236.2017.1405476 <br />
          <br />
          Roy, R. Social Support, Health, and Illness: A Complicated Relationship. University of
          Toronto Press, 2011.
        </p>
      </Box>
    </Box>
  );
}
