export const testData = {
  receiver: {
    account: {
      userId: "bca57770-643d-49d9-9985-3ed4b7011283",
      username: "Receiver",
      name: {
        firstName: "Temp",
        lastName: "Receiver",
        fullName: "Temp Receiver",
      },
      birthday: "06-14-1986",
      type: "Receiver",
      numOfSupporters: 17,
      numOfGroups: 2,
      privacy: "Private",
      picture: "",
      bio: "This is the fake bio for a receiver. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Justo nec ultrices dui sapien eget mi proin sed. Orci nulla pellentesque dignissim enim. Amet justo donec enim diam vulputate ut pharetra sit amet. Pellentesque dignissim enim sit amet venenatis. Faucibus et molestie ac feugiat sed lectus vestibulum mattis.",
    },
    groups: [
      {
        groupName: "Friends",
        dateJoined: "04-13-2022",
        groupStatus: "Private",
        members: [
          { name: "Amaranthia Willers", username: "awillers23" },
          { name: "Samantha Culver", username: "sam.culver" },
          { name: "Brayden Ruch", username: "bruch1283" },
        ],
      },
      {
        groupName: "Work",
        dateJoined: "04-16-2022",
        groupStatus: "Private",
        members: [
          { name: "Work friend 1", username: "wfriend1" },
          { name: "Work friend 2", username: "wfriend2" },
        ],
      },
      {
        groupName: "Community",
        dateJoined: "04-18-2022",
        groupStatus: "Public",
        members: [
          { name: "Community friend 1", username: "comfriend1" },
          { name: "Community friend 2", username: "comfriend2" },
          { name: "Community friend 3", username: "comfriend3" },
        ],
      },
    ],
    calendar: [
      {
        date: "04-16-2022",
        type: "food",
        label: "Dinner with Jane",
      },
      {
        date: "04-29-2022",
        type: "medical",
        label: "Hospital appointment",
      },
      {
        date: "05-18-2022",
        type: "social",
        label: "Coffee meetup",
      },
    ],
    calendarDates: [16, 18, 29],
    activity: [
      {
        username: "Supporter",
        userId: "1716909f-5174-44bb-a022-d35679ea9ff8",
        activityType: "Request",
        data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        timePosted: "April 16, 2022 - 7:54 am",
      },
      {
        username: "Supporter",
        userId: "1716909f-5174-44bb-a022-d35679ea9ff8",
        activityType: "Update",
        data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        timePosted: "April 14, 2022 - 9:23 am",
      },
      {
        username: "Supporter",
        userId: "1716909f-5174-44bb-a022-d35679ea9ff8",
        activityType: "Misc",
        data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        timePosted: "April 13, 2022 - 12:08 pm",
      },
    ],
    requests: {
      goFundMe: "www.gofundme.com",
      requestsList: [
        {
          title: "Daily meals",
          supporter: "Brayden Ruch",
          status: "Unfulfilled",
          date: "04/27/22",
          description:
            "I have been having a hard time this week finding the time to make dinner and would love if someone could provide a few quick meals for my family and I.",
        },
        {
          title: "Pickup Child from school",
          supporter: "Samantha Culver",
          status: "Scheduled",
          date: "04/25/22",
          description:
            "I need someone to pick Billy up from school on Monday, April 25th. He gets out of school around 3:45 and usually waits by the tree near the baseball fields.",
        },
        {
          title: "Get coffee and chat",
          supporter: "Amaranthia Willers",
          status: "Completed",
          date: "04/20/22",
          description:
            "I'm looking for someone to grab some coffee with and chat about life recently. I'll pay for us both but it would be nice to take a break for a bit and have some new conversation.",
        },
      ],
    },
    updates: [
      {
        description:
          "Had coffee with a friend over the weekend and it was a much needed break. Its always funny how a simple meetup can uplift my mood.",
        timePosted: "June 23, 2022 - 10:53 am",
      },
      {
        description:
          "Thank you all for your support this last week. The procedure went well and I am expected to make a full recovery in the coming months. Thank you all for your donations, whether it be your time or efforts. You all helped me tremendously. Thank you again",
        timePosted: "May 14, 2022 - 7:34 pm",
      },
      {
        description:
          "I'd like to ask anyone who is willing to keep me in their thoughts and prayers in this hard time. I know there are better days ahead, but it can be hard to see those days when the current situation is not so great.",
        timePosted: "March 30, 2022 - 3:25 pm",
      },
    ],
  },
  supporter: {
    account: {
      userId: "1716909f-5174-44bb-a022-d35679ea9ff8",
      username: "Supporter",
      name: {
        firstName: "Amaranthia",
        lastName: "Willers",
        fullName: "Amaranthia Willers",
      },
      birthday: "06-12-1994",
      type: "Receiver",
      numOfSupporters: 80,
      numOfGroups: 4,
      privacy: "Private",
      bio: "Hello, my name is Amaranthia Willers. I am a senior in Computer Engineering at Iowa State.",
    },
    calendar: [
      {
        date: "04-12-2022",
        type: "food",
        label: "Dinner with Jane",
      },
      {
        date: "04-26-2022",
        type: "medical",
        label: "Hospital appointment",
      },
      {
        date: "05-17-2022",
        type: "social",
        label: "Coffee meetup",
      },
    ],
    calendarDates: [12, 17, 26],
    activity: [
      {
        username: "Kellan Hulet",
        userId: "bca57770-643d-49d9-9985-3ed4b7011283",
        activityType: "Request",
        data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        timePosted: "April 16, 2022 - 7:54 am",
      },
      {
        username: "Samantha Culver",
        userId: "bca57770-643d-49d9-9985-3ed4b7011283",
        activityType: "Update",
        data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        timePosted: "April 14, 2022 - 9:23 am",
      },
      {
        username: "Brayden Ruch",
        userId: "bca57770-643d-49d9-9985-3ed4b7011283",
        activityType: "Groups",
        data: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        timePosted: "April 13, 2022 - 12:08 pm",
      },
    ],
    requests: {
      goFundMe: "www.gofundme.com",
      requestsList: [
        {
          title: "Daily meals",
          supporter: "Brayden Ruch",
          completed: false,
          date: "04/27/22",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        },
        {
          title: "Pickup Child from school",
          supporter: "Samantha Culver",
          completed: false,
          date: "04/25/22",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        },
        {
          title: "Get coffee and chat",
          supporter: "Kellan Hulet",
          completed: true,
          date: "04/20/22",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        },
      ],
    },
    updates: [
      {
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        timePosted: "June 23, 2022 - 10:53 am",
      },
      {
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        timePosted: "May 14, 2022 - 7:34 pm",
      },
      {
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut diam. In fermentum posuere urna nec tincidunt praesent semper feugiat nibh. At tellus at urna condimentum. Aliquam id diam maecenas ultricies mi eget mauris. Ornare arcu odio ut sem nulla pharetra diam sit amet.",
        timePosted: "March 30, 2022 - 3:25 pm",
      },
    ],
  },
};
