import React, { useContext } from 'react'

import { AuthContext } from '../AuthContext'

const Navbar = () => {
  const {currentUser} = useContext(AuthContext)
console.log(currentUser);
  return (
    <div className='navbar'>
      <div className="user">
        <img src={currentUser.photoURL} alt="" />
        <span>{currentUser.displayName}</span>
        
      </div>
    </div>
  )
}

export default Navbar