import React from "react";
import { Box, Heading, Text, Grid, Button, List } from "grommet";
import { Grow, Login, Group, New } from "grommet-icons";
import { Link } from "react-router-dom";

function Home() {
  return (
    <Box>
      <Box background="url('hands2.jpg')" fill>
        <Heading alignSelf="start" margin={{ vertical: "150px", left: "50px" }}>
          Getting support can be hard
        </Heading>
        <Heading alignSelf="end" margin={{ vertical: "175px", right: "100px" }}>
          We're here to help
        </Heading>
      </Box>
      <Box background="brand" fill>
        <Heading margin={{ vertical: "small", left: "medium" }}>How it works...</Heading>
        <Grid
          rows={["medium"]}
          columns={[{ count: "fill", size: "medium" }]}
          gap="medium"
          areas={[
            { name: "left", start: [0, 0], end: [1, 0] },
            { name: "center", start: [1, 0], end: [1, 0] },
            { name: "right", start: [2, 0], end: [2, 0] },
          ]}
          margin={{ horizontal: "medium" }}
        >
          <Box
            gridArea="left"
            background="brand_gray"
            align="center"
            round
            gap="small"
            border={{ size: "large", side: "all", color: "brand_gray" }}
          >
            <Grow />
            <Text>
              To use this website, it is important to first register your account. It can easily be
              done by clicking the button below which will take you through the process of
              registration. There are two primary user types within this website, though users can
              fall under both user categories simultaneously if they so choose.
            </Text>
          </Box>
          <Box
            gridArea="center"
            background="brand_gray"
            align="center"
            gap="small"
            round
            border={{ size: "large", side: "all", color: "brand_gray" }}
          >
            <Login />
            <Text>
              The first user type is the user in-need. These users created an account on this
              website to help bring attention to their community about support they seriously need.
              Often, there are people who are in crisis who seriously need emotional and tangible
              support who don't effectively get what they need. This website serves to help organize
              their needs in one place and allow their support network to more effectively figure
              out what their loved ones need most in their most stressful times.
            </Text>
          </Box>
          <Box
            gridArea="right"
            background="brand_gray"
            align="center"
            gap="small"
            round
            border={{ size: "large", side: "all", color: "brand_gray" }}
          >
            <Group />
            <Text>
              The second primary user type is the supporter category. These users are community
              members who want to assist their loved ones as well as individuals who simply want to
              support others in need. These users are able to search for specific people in need to
              follow, so they can more easily organize when people need help as well as what kind of
              help they need. The suppporters can also interact with other users' calendars to sign
              up for different support tasks, find out other users' availability for emotional
              support networks, and the like. They can also message other users to communicate plans
              and determine the best way to help those in need.
            </Text>
          </Box>
        </Grid>
      </Box>
      <Box background="brand" align="center" height="80px" justify="center">
        <Link to="/login">
          <Button label="Register now to start recieving support" size="large" />
        </Link>
      </Box>
      <Box>
        <Box direction="row" justify="between" margin={{ horizontal: "medium" }} align="center">
          <Heading alignSelf="start">Our mission:</Heading>
          <Link to="/mission">
            <Button label="Full Mission Statement" style={{ height: "100px" }} />
          </Link>
        </Box>
        <List
          margin={{ horizontal: "medium" }}
          primaryKey="icon"
          secondaryKey="text"
          data={[
            {
              icon: <New />,
              text: "The goal of Parakeet is to help individuals get the type and amount of support they need, when it's most neeeded.",
            },
            {
              icon: <New />,
              text: "Many people struggle to get the support they need, despite loved ones frequently offering to 'be there for whatever' they need.",
            },
            {
              icon: <New />,
              text: "This website is to help users organize those offers of aid, and get the help that they most need in an efficient and effective manner.",
            },
          ]}
        />
      </Box>
    </Box>
  );
}

export default Home;
