import React, { useState } from "react";
import { useParams, NavLink } from "react-router-dom";
import { Box, Button, Heading, Grid, Avatar, Text, Calendar, List, Stack } from "grommet";
import { New, User } from "grommet-icons";
import ActivityPage from "./ActivityPage";
import { testData } from "../../testData";

function fetchUserJSON(username) {
  switch (username) {
    case "a6824047-a8e1-4953-8af2-fabf6a9f2e76":
      return testData.receiver;
    case "1716909f-5174-44bb-a022-d35679ea9ff8":
      return testData.supporter;
    default:
      return testData.receiver;
  }
}

function Account() {
  const [title, setTitle] = useState("Activity");
  const { username } = useParams();
  const userJSON = fetchUserJSON(username);
  const { numOfGroups, numOfSupporters, name } = userJSON.account;
  const calendarContent = userJSON.calendarDates;
  const [selectedDay, setSelectedDay] = useState(new Date().toISOString());
  const onSelect = (value) => {
    setSelectedDay(value);
  };

  return (
    <Grid
      rows={["xlarge"]}
      columns={["1/3", "flex", "1/3"]}
      gap="small"
      areas={[
        { name: "left", start: [0, 0], end: [0, 0] },
        { name: "middle", start: [1, 0], end: [1, 0] },
        { name: "right", start: [2, 0], end: [2, 0] },
      ]}
    >
      <Box gridArea="left" background="brand_gray" align="center">
        <Box direction="row" align="center" gap="small">
          <Avatar
            size="large"
            border="black"
            src={`${process.env.PUBLIC_URL}/assets/${userJSON.account.username}.jpg`}
          >
            <User />
          </Avatar>
          <Heading>{name.fullName}</Heading>
        </Box>
        <Box direction="row" gap="medium">
          <Text size="large" selectable={false}>
            Supporters: {numOfSupporters}
          </Text>
          <Text size="large" onClick={() => setTitle("Groups")}>
            Groups: {numOfGroups}
          </Text>
        </Box>
        <Box direction="column" margin={{ top: "medium" }} gap="medium">
          <Button label="Activity" onClick={() => setTitle("Activity")} />
          <Button label="Requests" onClick={() => setTitle("Requests")} />
          <Button label="Request Support" onClick={() => setTitle("Request Support")} />
          <Button label="Groups" onClick={() => setTitle("Groups")} />
          <Button label="Account Settings" onClick={() => setTitle("Account Settings")} />
          <Button label="Help" onClick={() => setTitle("Help")} />
        </Box>
      </Box>
      <Box fill gridArea="middle" background="brand">
        <Heading textAlign="center">{title}</Heading>
        <ActivityPage menuChoice={title} userData={userJSON} />
      </Box>
      <Box fill gridArea="right" background="brand_gray" align="center">
        <NavLink style={{ textDecoration: "none", color: "#444444" }} to="/calendar">
          <Heading>Calendar</Heading>
        </NavLink>
        <Box width="80%">
          <Calendar date={selectedDay} fill>
            {({ date, day, isSelected }) => {
              const hasContent = calendarContent.includes(day);
              return (
                <Box
                  background={isSelected ? "light-3" : "#bdc5d0"}
                  onClick={() => onSelect(date.toISOString())}
                  border
                  fill
                >
                  <Stack anchor="top-right" fill>
                    <Box fill justify="center" align="center" pad="small">
                      <Text size="large">{day}</Text>
                    </Box>
                    {hasContent ? (
                      <Box pad="xxsmall">
                        <New color="brand" size="16px" />
                      </Box>
                    ) : null}
                  </Stack>
                </Box>
              );
            }}
          </Calendar>
        </Box>
        <Heading>Upcoming Events</Heading>
        <List
          margin={{ horizontal: "medium" }}
          primaryKey="icon"
          secondaryKey="text"
          data={userJSON?.requests?.requestsList.map((request) => {
            return {
              icon: <New />,
              text: request.title + " - " + request.date,
            };
          })}
        />
      </Box>
    </Grid>
  );
}

export default Account;
