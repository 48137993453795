import React, { useState } from "react";
import { Form, FormField, Button, TextInput, Box, Text, TextArea } from "grommet";
import { Checkmark } from "grommet-icons";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

function sendEmail(formParams) {
  emailjs.send("service_mxihysr", "template_d8smgn1", formParams, "WUlaDc7BiupJcgcS_").then(
    function (response) {
      toast.success("Feedback sent!");
      console.log("SUCCESS!", response.status, response.text);
    },
    function (error) {
      toast.error("Failed to send feedback");
      console.log("FAILED...", error);
    }
  );
}

function ListComponent({ text }) {
  return (
    <Box direction="row" align="center" gap="xsmall" margin={{ bottom: "xsmall" }}>
      <Checkmark />
      <Text direction="row">{text}</Text>
    </Box>
  );
}

const defaultValues = {
  from_name: "",
  feedback: "",
};

function Register() {
  const [value, setValue] = useState(defaultValues);

  const onSubmit = (value) => {
    setValue(defaultValues);
    sendEmail(value);
  };
  return (
    <Box background="brand_gray" fill>
      <Box margin={{ top: "large" }} justify="center" gap="small" align="center" direction="row">
        <Box width="medium" border={{ side: "right" }}>
          <Box border={{ side: "bottom" }} margin={{ bottom: "small" }}>
            <Text>
              Thank you for taking the time to provide feedback on our application! Here is a list
              of current design improvements to reduce any duplicate requests:
            </Text>
          </Box>
          <ListComponent text="Dark mode" />
          <ListComponent text="Update account view design" />
          <ListComponent text="Update header from basic design" />
          <Box border={{ side: "top" }} margin={{ top: "small" }}>
            <Text>
              Please feel free to leave feedback on anything that comes to mind including color
              scheme, layout, navigation, functonality that is missing or could be improved upon.
            </Text>
          </Box>
        </Box>
        <Box gap="small" width="medium" margin="small">
          <Form
            value={value}
            onChange={(nextValue) => setValue(nextValue)}
            onReset={() => setValue(defaultValues)}
            onSubmit={({ value }) => onSubmit(value)}
          >
            <FormField name="from_name" label="Name" required>
              <TextInput name="from_name" />
            </FormField>
            <FormField name="feedback" label="Feedback" required>
              <TextArea name="feedback" />
            </FormField>
            <Box direction="row" gap="medium">
              <Button type="submit" label="Submit" />
              <Button type="reset" label="Reset" />
            </Box>
          </Form>
        </Box>
      </Box>
    </Box>
  );
}

export default Register;
