// import React, { useEffect } from "react";
// import { Box, Button } from "grommet";
// import { Authenticator } from "@aws-amplify/ui-react";
// import { signInWithEmailAndPassword } from "firebase/auth";
// import { auth } from "../firebase";

// function Register() {

//   const handleSubmit = async (e) => {
//     try {
//       await signInWithEmailAndPassword(auth, "sid1@iastate.edu", "123456");
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   return (
//     <Authenticator>
//       {({ signOut }) => (
//         <Box margin={{ horizontal: "small", vertical: "small" }} width="small">
//           <Button onClick={signOut} label="Sign Out" />
//         </Box>
//       )}
//     </Authenticator>
//   );
// }

// export default Register;

import React, { useState } from "react";
import { Form, FormField, Button, TextInput, Box } from "grommet";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box justify="start" gap="small" align="center">
      <Box gap="small" width="medium" margin="small">
        <Form>
          <FormField name="username" label="Username">
            <TextInput
              name="name"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormField>
          <FormField name="password" label="Password">
            <TextInput
              type="password"
              name="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </FormField>
          <Box direction="row" gap="medium">
            <Button type="submit" label="Submit" onClick={handleSubmit} />
            <Link to="/register">
              <Button label="Register" />
            </Link>
          </Box>
        </Form>
      </Box>
    </Box>
  );
}

export default Login;
