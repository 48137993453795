import React, { useState } from "react";
import { Box, Calendar, List, Heading, Text, Stack } from "grommet";
import { New } from "grommet-icons";

function CalendarPage() {
  const calendarContent = [3, 16, 26];
  const [selectedDay, setSelectedDay] = useState(new Date().toISOString());
  const onSelect = (value) => {
    setSelectedDay(value);
  };
  return (
    <Box direction="row">
      <Calendar date={selectedDay} size="large" fill margin={{horizontal:"small"}}>
        {({ date, day, isSelected }) => {
          const hasContent = calendarContent.includes(day);
          return (
            <Box
              background={isSelected ? "light-3" : "white"}
              onClick={() => onSelect(date.toISOString())}
              border
              fill
            >
              <Stack anchor="top-right" fill>
                <Box
                  fill
                  justify="center"
                  align="center"
                  pad="small"
                >
                  <Text size="large">{day}</Text>
                </Box>
                {hasContent ? (
                  <Box pad="xxsmall">
                    <New color="brand"/>
                  </Box>
                  
                ) : null}
              </Stack>
            </Box>
          );
        }}
      </Calendar>
      <Box>
        <Heading size="small">Upcoming dates:</Heading>
        <List
          margin={{ horizontal: "medium" }}
          primaryKey="icon"
          secondaryKey="text"
          data={[
            {
              icon: <New />,
              text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae turpis massa sed elementum tempus.",
            },
            {
              icon: <New />,
              text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae turpis massa sed elementum tempus.",
            },
            {
              icon: <New />,
              text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae turpis massa sed elementum tempus.",
            },
          ]}
        />
      </Box>
    </Box>
  );
}

export default CalendarPage;
