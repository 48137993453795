import React, { useEffect, useState } from "react";
import { Box, Image, DropButton, Text, Button } from "grommet";
import { NavLink } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import { useNavigate } from "react-router-dom";

const linkStyle = {
  textDecoration: "none",
  color: "white",
};

function Header() {
  const nav = useNavigate();
  const [userAttributes, setUserAttributes] = useState();

  const signOutUser = async () => {
    await Auth.signOut();
    nav("/login");
  };

  const getUserAttributes = async () => {
    await Auth.currentAuthenticatedUser().then((user) => setUserAttributes(user?.attributes));
  };

  useEffect(() => {
    Hub.listen("auth", (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        getUserAttributes();
      }
      if (payload.event === "signOut") {
        setUserAttributes();
      }
    });
  }, []);

  let profile =
    userAttributes?.sub === "1716909f-5174-44bb-a022-d35679ea9ff8" ? "supporter" : "receiver";

  return (
    <>
      <Box
        tag="header"
        direction="row"
        align="center"
        justify="evenly"
        background="brand"
        pad={{ left: "medium", right: "small", vertical: "small" }}
        elevation="medium"
        style={{ zIndex: "1" }}
      >
        <Box direction="row" gap="large" align="center" justify="between">
          <Box direction="row" align="center">
            {userAttributes && (
              <Text size="large">
                {userAttributes?.given_name + " " + userAttributes?.family_name}{" "}
              </Text>
            )}
          </Box>
          {/* AWS Cognito will not drop current logged in user when restarting localapp. 
              Uncomment below code to manually log cognito user out*/}

          {/* <Button style={linkStyle} onClick={() => signOutUser()}>
            Logout
          </Button> */}
          <NavLink to="/" style={linkStyle}>
            <Box height="40px">
              <Image fit="contain" src={process.env.PUBLIC_URL + "/ParakeetModernLogo2.jpg"} />
            </Box>
          </NavLink>
        </Box>
        <Box direction="row" gap="small" align="center" justify="end">
          <DropButton
            label="About"
            plain
            dropAlign={{ top: "bottom", right: "right" }}
            dropContent={
              <Box gap="small" background="brand" border={{ color: "brand", size: "medium" }}>
                <NavLink style={linkStyle} to="/about">
                  About
                </NavLink>
                <NavLink style={linkStyle} to="/mission">
                  Mission
                </NavLink>
                <NavLink to="/feedback" style={linkStyle}>
                  Feedback
                </NavLink>
              </Box>
            }
          />
          <NavLink style={linkStyle} to="/search">
            Search
          </NavLink>
          {userAttributes ? (
            <NavLink style={linkStyle} to={`/account/${profile}`}>
              Account
            </NavLink>
          ) : null}
          <NavLink style={linkStyle} to="/messages">
            Messages
          </NavLink>
          {userAttributes ? (
            <Button style={linkStyle} onClick={() => signOutUser()}>
              Logout
            </Button>
          ) : (
            <NavLink to="login" style={linkStyle}>
              Login
            </NavLink>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Header;
