import React from "react";
import { Box, TextInput, CheckBoxGroup } from "grommet";

function Search() {
  return (
    <Box justify="start" gap="small" align="center">
      <Box gap="small" width="medium" margin="small">
        <TextInput placeholder="Search Parakeet" />
        <CheckBoxGroup
          direction="row"
          options={["User", "Group", "Organization"]}
        />
      </Box>
    </Box>
  );
}

export default Search;
