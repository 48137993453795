import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Box, Button, Heading, Grid, Avatar, Text, List } from "grommet";
import { FormLock, Checkmark, More, Chat, Favorite } from "grommet-icons";
import { testData } from "../../testData";

function fetchUserJSON(username) {
  switch (username) {
    case "a6824047-a8e1-4953-8af2-fabf6a9f2e76":
      return testData.receiver;
    case "1716909f-5174-44bb-a022-d35679ea9ff8":
      return testData.supporter;
    default:
      return testData.receiver;
  }
}

function DisplayCurrentRequests({ text }) {
  return (
    <Box gap="xsmall">
      <Box>
        <Text>{text}</Text>
      </Box>
      <Box>
        <Button label="Support?" />
      </Box>
    </Box>
  );
}

function UpdateDisplay({ userData, username, fullName }) {
  const [changeStyle, setChangeStyle] = useState("white");
  return (
    <Box>
      <List data={userData} pad="small" align="center">
        {(datum) => (
          <Grid
            columns={["xxsmall", "flex"]}
            rows={["flex"]}
            gap="xsmall"
            areas={[
              { name: "avatar", start: [0, 0], end: [0, 0] },
              { name: "main", start: [1, 0], end: [1, 0] },
            ]}
          >
            <Box gridArea="avatar" width="50px">
              <Avatar size="40px" src={`${process.env.PUBLIC_URL}/assets/${username}.jpg`} />
            </Box>
            <Box gridArea="main" gap="small">
              <Box direction="row" justify="between" align="center">
                <Text size="large">{fullName}</Text>

                <Text size="medium">{datum.timePosted}</Text>
              </Box>
              <Text>{datum.description}</Text>

              <Box direction="row" gap="medium">
                <Box direction="row" gap="small">
                  <Chat />
                  <Text>15</Text>
                </Box>
                <Box direction="row" gap="small">
                  <Favorite
                    color={changeStyle}
                    onClick={() => setChangeStyle(changeStyle === "white" ? "red" : "white")}
                  />
                  <Text>205</Text>
                </Box>
              </Box>
            </Box>
          </Grid>
        )}
      </List>
    </Box>
  );
}

function ViewAccount() {
  const { username } = useParams();
  const userJSON = fetchUserJSON(username);
  const { numOfGroups, numOfSupporters, name, username: usernameText } = userJSON.account;

  return (
    <Grid
      rows={["xlarge"]}
      columns={["1/3", "flex", "1/3"]}
      gap="small"
      areas={[
        { name: "left", start: [0, 0], end: [0, 0] },
        { name: "middle", start: [1, 0], end: [1, 0] },
        { name: "right", start: [2, 0], end: [2, 0] },
      ]}
      margin={{ horizontal: "xlarge" }}
    >
      <Box gridArea="left" background="brand_gray" align="center">
        <Box direction="row" align="center">
          <Avatar size="xlarge" src={`${process.env.PUBLIC_URL}/assets/${usernameText}.jpg`} />
          <Heading textAlign="center">{name.fullName}</Heading>
          {userJSON.account.privacy === "Private" ? (
            <FormLock size="large" color="#444444" />
          ) : null}
        </Box>
        <Box direction="row" align="center" gap="small">
          <Button label="Support" />
          <Link to="/messages">
            <Button label="Message" />
          </Link>
        </Box>
        <Box
          direction="row"
          gap="medium"
          width="100%"
          justify="center"
          border={{ side: "bottom", size: "small" }}
          pad="small"
        >
          <Text size="large">Supporters: {numOfSupporters}</Text>
          <Text size="large">Groups: {numOfGroups}</Text>
        </Box>
        <Box width="65%" direction="column" margin={{ top: "small" }} gap="medium">
          <Text textAlign="center">{userJSON.account.bio}</Text>
        </Box>
      </Box>
      <Box fill gridArea="middle" background="brand">
        <Heading textAlign="center">{name.firstName}'s Updates</Heading>
        <UpdateDisplay
          userData={userJSON.updates}
          username={usernameText}
          fullName={name.fullName}
        />
      </Box>
      <Box fill gridArea="right" background="brand_gray" align="center">
        <Heading textAlign="center">Active Requests</Heading>
        <a href={"https://" + userJSON?.requests?.goFundMe} target="_blank" rel="noreferrer">
          {userJSON?.requests?.goFundMe}
        </a>
        <List
          margin={{ horizontal: "medium" }}
          primaryKey="icon"
          secondaryKey="text"
          data={userJSON?.requests?.requestsList.map((request) => {
            return {
              icon: request.completed ? <Checkmark /> : <More />,
              text: <DisplayCurrentRequests text={request.description} />,
            };
          })}
        />
      </Box>
    </Grid>
  );
}

export default ViewAccount;
